import React, {Component} from "react";
import axios from "axios";
import {capitalize} from "../utils";
import ReactGA from 'react-ga';

import Card from "./Card";
import RegResult from "./RegResult";
import SignupForm from "./SignupForm";
import PassportBadge from "./PassportBadge";

import ImgPhone from "../assets/img/phone.png";
import ImgCard from "../assets/img/card.svg";
import {ReactComponent as SolLogo} from "../assets/icons/sol-logo.svg";

const client = axios.create({
    baseURL: "https://api.solcard.co.za/agent",
    headers: {
        "X-Client-Type": "web",
        "X-Client-Version": 1
    }
});

ReactGA.initialize("UA-139749249-5");

const AGENT_FOUND = 1;
const REGISTER_SUCCESS = 2;
const REGISTER_FAIL = 3;
const ERROR = 4;

const ERR_BAD_REQUEST = 400;
const ERR_NOT_FOUND = 404;

export default class App extends Component {
    agent = null;

    state = {
        status: 0,
        formData: {
            phone: "",
            name: ""
        },
        error: null
    };

    async componentDidMount() {
        const hostParts = window.location.hostname.split(".");


        let agentAlias = hostParts[0];
        if (window.location.hostname === 'localhost') {
            agentAlias = '27627873413';
        } else if (hostParts.length < 2) {
            this.setState({
                status: ERROR,
                error: {
                    code: ERR_NOT_FOUND,
                    message: "Page not found"
                }
            });
            return;
        }


        try {
            const resp = await client.get("/get-by-alias", {
                params: {
                    alias: agentAlias
                }
            });
            const {first_name, last_name, phone, business_name} = resp.data.result.agent;
            this.agent = {
                alias: agentAlias,
                firstName: first_name,
                lastName: last_name,
                phone: phone,
                businessName: business_name
            };
            this.setState({status: AGENT_FOUND});
        } catch (e) {
            this.handleError(e);
        }
    }

    handleChange = e => {
        e.persist();
        this.setState(prevState => {
            const formData = {
                ...prevState.formData,
                [e.target.name]: e.target.value
            };
            return {
                formData
            };
        });
    };

    registerClient = async formData => {
        try {
            await client.post("/web-register-client", {
                alias: this.agent.alias,
                ...formData
            });
            this.setState({status: REGISTER_SUCCESS});
        } catch (e) {
            if (e.response) {
                this.handleError(e);
            }
        }
    };

    handleError = error => {
        if (error.response) {
            const resp = error.response;

            if (resp.status === ERR_NOT_FOUND) {
                this.setState({
                    status: ERROR,
                    error: {
                        code: resp.status,
                        message: resp.data.request_error.message
                    }
                });
            } else if (resp.status === ERR_BAD_REQUEST) {
                switch (resp.data.params_error.error_code) {
                    case "already_exist":
                        this.setState({
                            status: REGISTER_FAIL,
                            error: {
                                code: resp.status,
                                message: resp.data.params_error.message
                            }
                        });
                        break;
                    default:
                        this.setState({
                            status: ERROR,
                            error: {
                                code: resp.status,
                                message: "Bad request"
                            }
                        });
                        break;
                }
            }
        } else {
            this.setState({
                status: ERROR,
                error: {
                    message: "Unexpected error happend"
                }
            });
        }
    };

    reset = () => {
        this.setState(prevState => {
            if (prevState.error) {
                return {
                    status: AGENT_FOUND,
                    error: null
                };
            }

            return {
                status: AGENT_FOUND,
                formData: {
                    phone: "",
                    name: ""
                }
            };
        });
    };

    getContent = () => {
        const {status, formData, error} = this.state;
        let content;

        switch (status) {
            case AGENT_FOUND:
                content = (
                    <div className="flex-grow flex flex-col md:flex-row-reverse md:max-h-618">
                        <div className="relative flex-grow">
                            <img className="bg-card" src={ImgCard} alt=""/>
                            <img className="bg-phone" src={ImgPhone} alt=""/>
                        </div>
                        <SignupForm
                            agent={this.agent}
                            formData={formData}
                            onChange={this.handleChange}
                            onSubmit={this.registerClient}
                        />
                        <PassportBadge/>
                    </div>
                );
                break;
            case ERROR:
                content = (
                    <Card>
                        {error && (
                            <h1 className="text-36 text-yellow font-semibold md:text-96 sm:text-66">
                                {error.code}
                            </h1>
                        )}
                        <h2 className="text-18 font-semibold mt-20 md:text-36 sm:text-24">
                            {capitalize(error.message)}
                        </h2>
                        {error.code === ERR_NOT_FOUND && (
                            <p className="text-14 leading-normal mt-30 md:text-24 sm:text-18">
                                You entered the wrong address, contact your agent or go to
                                &nbsp;
                                <a
                                    href="https://solcard.co.za"
                                    className="text-yellow"
                                    title="solcard site"
                                >
                                    solcard.co.za
                                </a>
                                &nbsp;and download the application.
                            </p>
                        )}
                    </Card>
                );
                break;
            case REGISTER_SUCCESS:
                content = (
                    <RegResult
                        success
                        text="Success, you were invited!"
                        onClick={this.reset}
                    />
                );
                break;
            case REGISTER_FAIL:
                content = <RegResult text={error.message} onClick={this.reset}/>;
                break;
            default:
                content = null;
                break;
        }

        return content;
    };

    render() {
        const content = this.getContent();

        return (
            <div className="relative flex-grow w-full mx-auto max-w-1096 flex flex-col">
                <header className="flex px-24 z-10 mt-30 mb-12 md:px-0">
                    <a href="#">
                        <SolLogo className="w-63 h-32 md:w-115 md:h-60"/>
                    </a>
                </header>
                <main className="flex-grow flex flex-col justify-center xs:max-h-800">
                    {content}
                </main>
            </div>
        );
    }
}
