import React from "react";
import { createPortal } from "react-dom";

import { ReactComponent as PassportIcon } from "../assets/icons/passport.svg";

export default function PassportBadge() {
  return createPortal(
    <div className="passport-badge leading-tight">
      <PassportIcon className="flex-shrink-0 w-32 h-32 mr-12 md:w-63 md:h-63" />
      Foreign passport allowed
    </div>,
    document.getElementById("root")
  );
}
