import React from "react";
import classNames from "classnames";

import Card from "./Card";
import Btn from "./Btn";

import { ReactComponent as CheckmarkIcon } from "../assets/icons/checkmark.svg";
import { ReactComponent as CrossIcon } from "../assets/icons/cross.svg";

export default function RegResult({ success, text, onClick }) {
  const classes = ["w-30 h-24 md:w-60 md:h-44"];

  let Icon;
  if (success) {
    Icon = CheckmarkIcon;
    classes.push("text-green-100");
  } else {
    Icon = CrossIcon;
    classes.push("text-red");
  }

  return (
    <Card>
      <div className="w-60 h-60 flex justify-center items-center bg-green-200 p-10 rounded-full mb-16 md:mb-34 md:w-120 md:h-120">
        <Icon className={classNames(classes)} />
      </div>
      <h2 className="text-18 leading-tight md:text-36 md:mb-34 sm:text-24">
        {text}
      </h2>
      <Btn type="primary" className="self-stretch mt-30" onClick={onClick}>
        Return
      </Btn>
    </Card>
  );
}
