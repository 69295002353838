import React, {Component} from "react";
import {capitalize, formatPhone} from "../utils";

import InputMask from "react-input-mask";
import Btn from "./Btn";

import {ReactComponent as PhoneIcon} from "../assets/icons/phone.svg";
import {ReactComponent as PlaceholderPicture} from "../assets/img/profile-placeholder.svg";

export default class SignupForm extends Component {
    submit = e => {
        e.preventDefault();

        const {name, phone} = this.props.formData;
        this.props.onSubmit({
            name,
            phone: phone.replace(/\s/g, "")
        });
    };

    render() {
        const {agent, formData, onChange} = this.props;
        const {phone, name} = formData;
        const isValidForm = phone && !phone.includes("_") && !!name;

        const formatChars = {
            '9': '[0-9]',
            '0': '[1-9]',
            'a': '[A-Za-z]',
            '*': '[A-Za-z0-9]'
        }

        let businessName = agent.businessName
        if (!businessName) {
            businessName = capitalize(agent.firstName) + ' ' + capitalize(agent.lastName)
        }

        return (
            <form
                className="self-center relative max-w-420 h-full flex-shrink-0 flex flex-col p-16 bg-green-300 rounded-lg rounded-b-none z-10 md:p-20 md:shadow-lg xs:rounded-b-lg"
                onSubmit={this.submit}
            >
                <div className="flex mb-20 items-center md:mb-35">
                    <PlaceholderPicture className="w-58 h-58 mr-12 rounded-full md:w-68 md:h-68"/>
                    <div className="font-semibold tracking-wide">
                        <h2 className="text-24 mb-12 md:mb-20">
                            {businessName}
                        </h2>
                        <p className="text-16">Your SOL representative</p>
                    </div>
                </div>
                <div className="flex mb-30 space-x-8">
                    <Btn
                        type="secondary"
                        className="iphone5gg:text-10"
                        href={`tel:+${agent.phone}`}
                    >
                        <PhoneIcon width="24" height="24" className="text-yellow mr-12"/>
                        {formatPhone(agent.phone)}
                    </Btn>
                    {/*<Btn*/}
                    {/*  type="secondary"*/}
                    {/*  className="iphone5gg:text-10"*/}
                    {/*  href="mailto:support@solcard.co.za"*/}
                    {/*>*/}
                    {/*  <MailIcon width="24" height="24" className="text-yellow mr-12" />*/}
                    {/*  dont-forget@to.add*/}
                    {/*</Btn>*/}
                </div>
                <p className="text-18 font-semibold tracking-wide mb-12 md:text-24">
                    Get your bank card
                </p>
                <p className="leading-normal mb-24">
                    If you accept the invitation, you can get your SOL Card online without any paperwork or branch
                    visits!
                </p>
                <InputMask
                    name="phone"
                    type="tel"
                    mask="+27 09 999 9999"
                    formatChars={formatChars}
                    value={phone}
                    onChange={onChange}
                    className="form-input mb-24 md:mb-34"
                    placeholder="Enter phone number"
                />
                <input
                    name="name"
                    className="form-input mb-34 md:mb-56"
                    onChange={onChange}
                    value={name}
                    placeholder="Enter name"
                />
                <Btn type="primary" disabled={!isValidForm}>
                    Join
                </Btn>
            </form>
        );
    }
}
