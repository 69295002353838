import React from "react";
import classNames from "classnames";

export default function Btn({ type, href, className, children, ...elProps }) {
  const isPrimary = type === "primary";

  const classes = [
    "btn",
    {
      "btn-primary": isPrimary,
      "btn-secondary": !isPrimary
    },
    className
  ];

  return href ? (
    <a className={classNames(classes)} href={href} {...elProps}>
      {children}
    </a>
  ) : (
    <button className={classNames(classes)} {...elProps}>
      {children}
    </button>
  );
}
